import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  // Array of image URLs
  const images = [
    'https://zqqzpfxqiwfbsfvfuqcu.supabase.co/storage/v1/object/public/Gallery/photo_10_2024-11-08_19-43-15.jpg',
    'https://zqqzpfxqiwfbsfvfuqcu.supabase.co/storage/v1/object/public/Gallery/photo_15_2024-11-08_19-43-15.jpg',
    'https://zqqzpfxqiwfbsfvfuqcu.supabase.co/storage/v1/object/public/Gallery/photo_5_2024-11-08_19-43-15.jpg',
    // Add more image URLs as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Changes image every 4 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative">
  {/* Content Overlay */}
  <div className="absolute inset-0 z-20 flex flex-col items-center justify-center text-white p-4">
    <div className="w-full max-w-4xl mx-auto text-center">
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold tracking-tight">
        <span className="block text-white mb-2">Sina Leather Products</span>
        
      </h1>
      
      <p className="mt-3 sm:mt-4 md:mt-6 text-sm sm:text-base md:text-lg lg:text-xl text-green-200 max-w-2xl mx-auto px-2">
        Discover our premium collection of handcrafted leather goods, where tradition meets modern design. Quality that speaks for itself.
      </p>

      <div className="mt-4 sm:mt-6 md:mt-8">
        <Link to="/product">
          <button className="px-4 sm:px-6 md:px-8 lg:px-10 
                           py-2 sm:py-2.5 md:py-3
                           text-sm sm:text-base md:text-lg
                           font-medium text-black bg-white rounded-md
                           hover:bg-gray-100 transition duration-300 ease-in-out
                           transform hover:-translate-y-1
                           focus:outline-none focus:ring-2 focus:ring-green-500">
            Explore Products
          </button>
        </Link>
      </div>
    </div>
  </div>

  {/* Background Image */}
  <div 
    className="relative w-full h-[50vh] sm:h-[55vh] md:h-[60vh] lg:h-[65vh] xl:h-[70vh]
               bg-cover bg-center transition-all duration-3000 ease-in-out"
    style={{ 
      backgroundImage: `url(${images[currentImageIndex]})`
    }}
  >
    <div className="absolute inset-0 bg-black opacity-40 bg-gradient-to-b from-transparent to-black/70"></div>
  </div>

  {/* Statistics Section */}
  <div className="absolute bottom-0 left-0 right-0 bg-black/50 py-2 sm:py-3 md:py-4 z-30">
    <div className="container mx-auto px-2 sm:px-4">
      <div className="grid grid-cols-3 gap-1 sm:gap-2 md:gap-4 text-white text-center">
        <div className="transition duration-300 ease-in-out p-1 sm:p-2">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold text-green-300">10K+</p>
          <p className="text-[10px] sm:text-xs md:text-sm lg:text-base">Proud Customers</p>
        </div>
        
        <div className="transition duration-300 ease-in-out p-1 sm:p-2">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold text-green-300">500+</p>
          <p className="text-[10px] sm:text-xs md:text-sm lg:text-base">Products</p>
        </div>
        
        <div className="transition duration-300 ease-in-out p-1 sm:p-2">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold text-green-300">25+</p>
          <p className="text-[10px] sm:text-xs md:text-sm lg:text-base">Countries Served</p>
        </div>
      </div>
    </div>
  </div>

  {/* Image Indicators */}
  <div className="absolute bottom-16 sm:bottom-20 left-1/2 transform -translate-x-1/2 z-30 flex space-x-1 sm:space-x-2">
    {images.map((_, index) => (
      <div
        key={index}
        className={`w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full transition-all duration-200 
                   ${currentImageIndex === index ? 'bg-white scale-125' : 'bg-white/50'}`}
      />
    ))}
  </div>
</div>
  );
};

export default Hero;