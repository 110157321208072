import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faClose, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import { supabase } from '../supabaseClient';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const sliderRef = useRef(null);
    const { scrollXProgress } = useScroll({
        container: sliderRef,
    });

    useEffect(() => {
        const fetchImages = async () => {
            const { data, error } = await supabase.storage.from('Gallery').list();
            if (error) {
                console.error('Error fetching images:', error);
                return;
            }
            const imageUrls = await Promise.all(
                data.map(async (file) => {
                    const { data: urlData } = await supabase.storage.from('Gallery').getPublicUrl(file.name);
                    return urlData.publicUrl;
                })
            );
            setImages(imageUrls);
        };
        fetchImages();
    }, []);

    const handlePrevImage = (e) => {
        e.stopPropagation();
        setSelectedImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };

    const handleNextImage = (e) => {
        e.stopPropagation();
        setSelectedImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (selectedImageIndex !== null) {
                if (e.key === 'ArrowLeft') handlePrevImage(e);
                if (e.key === 'ArrowRight') handleNextImage(e);
                if (e.key === 'Escape') setSelectedImageIndex(null);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedImageIndex]);

    return (
        <div className="container mx-auto px-4">
            {/* Mobile View */}
            <div className="md:hidden overflow-x-auto scrollbar-hide">
                <div className="flex space-x-4 pb-4 px-2 min-w-min">
                    {images.map((src, index) => (
                        <motion.div
                            key={index}
                            whileTap={{ scale: 0.95 }}
                            className="flex-shrink-0 w-80 group relative overflow-hidden rounded-xl shadow-lg cursor-pointer transition-transform duration-300"
                            onClick={() => setSelectedImageIndex(index)}
                        >
                            <img 
                                src={src} 
                                alt={`Gallery image ${index + 1}`} 
                                className="w-full h-50 object-cover transition-transform duration-300 group-hover:scale-105" 
                                loading="lazy" 
                            />
                        </motion.div>
                    ))}
                </div>
            </div>

            {/* Desktop Horizontal Slider */}
            <div className="hidden md:block relative">
                <motion.div 
                    ref={sliderRef}
                    className="flex overflow-x-scroll space-x-6 pb-6 px-4 scrollbar-hide"
                    style={{ 
                        cursor: 'grab',
                        userSelect: 'none',
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                >
                    {images.map((src, index) => (
                        <motion.div
                            key={index}
                            className="flex-shrink-0 w-[400px] h-[400px] group relative"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => setSelectedImageIndex(index)}
                        >
                            <img 
                                src={src} 
                                alt={`Gallery image ${index + 1}`} 
                                className="w-full h-full object-cover rounded-xl shadow-lg transition-transform duration-300 group-hover:brightness-90" 
                                loading="lazy" 
                            />
                        </motion.div>
                    ))}
                </motion.div>
                
                {/* Progress Indicator */}
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-200 mt-4">
                    <motion.div 
                        style={{ 
                            scaleX: scrollXProgress,
                            transformOrigin: 'left center'
                        }} 
                    />
                </div>
            </div>

            {/* Image Modal */}
            <AnimatePresence>
                {selectedImageIndex !== null && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/95 backdrop-blur-sm flex items-center justify-center z-50"
                        onClick={() => setSelectedImageIndex(null)}
                    >
                        <motion.button 
                            className="absolute top-6 right-6 text-white z-50 p-2" 
                            onClick={() => setSelectedImageIndex(null)}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <FontAwesomeIcon icon={faClose} size="2x" />
                        </motion.button>
                        <motion.button 
                            className="absolute left-4 md:left-8 text-white z-50 p-4" 
                            onClick={handlePrevImage}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                        </motion.button>
                        <motion.button 
                            className="absolute right-4 md:right-8 text-white z-50 p-4" 
                            onClick={handleNextImage}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} size="2x" />
                        </motion.button>
                        <motion.div 
                            className="relative max-w-[50%] max-h-[80vh]" 
                            initial={{ scale: 0.9, opacity: 0 }} 
                            animate={{ scale: 1, opacity: 1 }} 
                            exit={{ scale: 0.9, opacity: 0 }} 
                            transition={{ type: "spring", damping: 20, stiffness: 300 }}
                        >
                            <img 
                                src={images[selectedImageIndex]} 
                                alt="Selected" 
                                className="w-full h-full object-contain rounded-lg shadow-lg" 
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const Testimonial = () => {
    return (
        <div className="bg-gray-50 flex flex-col  m-0">
        
      
            <header className="relative h-[250px]">
                <div className="absolute inset-0 bg-[url('https://static.vecteezy.com/system/resources/thumbnails/026/542/204/small_2x/landscape-natural-beautiful-mountains-and-blue-sky-panorama-photo.jpg')] bg-cover bg-center">
                    <div className="absolute inset-0 bg-black/60 flex items-center justify-center text-center text-white px-2">
                        <div>
             
                            
                            <h1 className="text-3xl md:text-4xl font-bold mb-2">Contact Us</h1>
                            <p className="text-base mb-3 text-gray-300">For any questions and suggestions</p>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="px-4 py-1.5 bg-white text-black rounded-full hover:bg-opacity-90 text-sm transition-colors"
                                onClick={() => window.location.href='tel:+251911233514'}
                            >
                                Call Now
                            </motion.button>
                        </div>
                    </div>
                </div>
            </header>
            <main className="flex-grow py-12 sm:py-16">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Our Gallery</h2>
                <Gallery />
            </main>
            <footer className="bg-gray-900 text-white mt-8">
                <div className="container mx-auto px-4 py-16 md:py-24 text-center">
                    <h1 className="text-3xl md:text-5xl font-bold text-white mb-6">Premium Leather Goods</h1>
                    <p className="text-lg md:text-xl text-gray-300 leading-relaxed">Experience the timeless quality and durability of Ethiopian leather products. Crafted with precision and a dedication to tradition, each piece showcases unique artisanal craftsmanship that has been passed down through generations. Our leather goods are perfect for those who value luxury, sustainability, and cultural authenticity.</p>
                    <div className="flex justify-center space-x-6 my-6">
                    <img src={"logowhite.png"} className="w-40" />

                    </div>

                    <div className="text-center text-xs text-gray-400">© 2025 Sina Leather | Bereker Yosef</div>
                </div>
            </footer>
        </div>
    );
};

export default Testimonial;