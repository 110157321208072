import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/NavBar';
import HeroSection from './components/hero';
import CategorySection from './components/CategorySection';
import Card from './components/card';
import Testimonial from './components/Testimonial';
import ProductDetail from './components/ProductDetail';
import AdminPage from './components/AdminPage';
import LoginPage from './components/LoginPage';
import AddProduct from './components/AddProduct';
import ProductPage from './components/product';
import CartPage from './components/CartPage';
import OrderSuccess from './components/OrderSuccess';
import ImageUpload from './components/ImageUpload';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = 'https://zqqzpfxqiwfbsfvfuqcu.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpxcXpwZnhxaXdmYnNmdmZ1cWN1Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyODQ0OTkyMCwiZXhwIjoyMDQ0MDI1OTIwfQ.jX29D3E4QuLo52R1UvARwH0fLP2xRD7464YrQYZI-vA';
const supabase = createClient(supabaseUrl, supabaseKey);

const Home = () => (
  <div>
    <HeroSection />
    <CategorySection />
    <Card />
    
    <Testimonial />
    
  </div>
);

const App = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    console.log('Login successful, navigating to admin page');
    navigate('/admin');
  };

  // Function to handle file upload to Supabase
  const handleUpload = async (file) => {
    try {
      const { data, error } = await supabase.storage
        .from('your-bucket-name') // Replace with your Supabase bucket name
        .upload(`uploads/${file.name}`, file);

      if (error) {
        throw error;
      }
      console.log('File uploaded successfully:', data);
      return data; // Return file data if needed elsewhere
    } catch (error) {
      console.error('Upload error:', error);
      throw error;
    }
  };

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/addproduct" element={<AddProduct />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/order-success" element={<OrderSuccess />} />
        
        {/* Add ImageUpload route */}
        <Route 
          path="/upload-image" 
          element={<ImageUpload onUpload={handleUpload} />} 
        />
      </Routes>
    </>
  );
};

export default App;
