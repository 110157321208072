// Navbar.js
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const navigate = useNavigate();

    const handleSubscribe = () => {
        navigate('/');
    };

    return (
        <nav>
            <div className="flex items-center justify-between  bg-gray-800 py-2">
                <div className=" text-white text-2xl font-bold mx-5 cursor-pointer" onClick={handleSubscribe}>
                      <img src={"logowhite.png"} className="w-10" />
                </div>
             

                <div className="flex space-x-4 mx-5 items-center">
                    <a href="https://www.instagram.com/sinaleather_/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className=" text-white text-x hover:text-blue-500" />
                    </a>
                    <a href="https://t.me/Arsewale" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTelegram} className=" text-white text-x hover:text-blue-400" />
                    </a>
                    <a href="https://wa.me/251911233514" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} className=" text-white text-x hover:text-green-500" />
                    </a>
                    <a href="mailto:mulubayush1@gmail.com">
                        <FontAwesomeIcon icon={faEnvelope} className=" text-white text-x hover:text-red-500" />
                    </a>
                    <a href="tel:+251911233514">
                        <FontAwesomeIcon icon={faPhone} className=" text-white text-x hover:text-gray-400" />
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;