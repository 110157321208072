import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHome, faBoxes, faShoppingCart, faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ProductDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orderInfo, setOrderInfo] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        quantity: 1,
        color: ''
    });
    const [colors, setColors] = useState([]);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchProduct = async () => {
            const { data, error } = await supabase
                .from('product')
                .select('*')
                .eq('id', id)
                .single();

            if (error) {
                console.error('Error fetching product:', error);
                setLoading(false);
                return;
            }

            setProduct(data);
            setLoading(false);
        };

        const fetchColors = async () => {
            const { data, error } = await supabase
                .from('colors')
                .select('*');

            if (error) {
                console.error('Error fetching colors:', error);
                return;
            }

            setColors(data);
        };

        fetchProduct();
        fetchColors();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrderInfo(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError('');

        try {
            if (!orderInfo.name || !orderInfo.email || !orderInfo.phone || !orderInfo.address || !orderInfo.color) {
                throw new Error('All fields are required!');
            }

            const { data, error } = await supabase
                .from('Order')
                .insert({
                    name: orderInfo.name,
                    phone: orderInfo.phone,
                    quantity: orderInfo.quantity,
                    product_id: product.id,
                    email: orderInfo.email,
                    address: orderInfo.address,
                    color: orderInfo.color,
                })
                .select();

            if (error) throw error;

            console.log('Order submitted successfully:', data);
            navigate('/order-success', { 
                state: { 
                    orderDetails: {
                        ...orderInfo,
                        product: product.name
                    }
                }
            });
        } catch (error) {
            console.error('Submission error:', error);
            setError('Error submitting the form. Please try again. ' + error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    if (loading) return <p>Loading...</p>;
    if (!product) return <p>Product not found</p>;

    return (
      <div className="min-h-screen bg-gray-100 product-detail-container">
          <header className="bg-white shadow-md sticky top-0 z-50">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                  <div className="flex items-center justify-between">
                      <Link to="/" className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300 text-sm sm:text-base">
                          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                          <span>Back to Shop</span>
                      </Link>
                      <h1 className="text-lg sm:text-2xl font-bold text-gray-900">Product Details</h1>
                  </div>
              </div>
          </header>
  
          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap -8">
                  <div className="bg-white rounded-xl shadow-lg p-4 sm:p-8">
                      <img 
                          src={product.image} 
                          alt={product.name} 
                          className="w-full h-48 sm:h-64 object-cover rounded-lg shadow-md mb-4 cursor-pointer" 
                          onClick={toggleModal}
                      />
                      <h1 className="font-bold text-black text-2xl sm:text-3xl mb-2">{product.name}</h1>
                      <h2 className="font-medium text-gray-600 text-lg sm:text-xl mb-4">${product.price}</h2>
                      <p className="text-gray-700 text-sm sm:text-base">{product.description}</p>
                  </div>
  
                  <div className="bg-white rounded-xl shadow-lg p-4 sm:p-8">
                      <h2 className="text-xl font-semibold mb-4 sm:mb-6 text-gray-900">Order Information</h2>
                      <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                          <div>
                              <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                                  Full Name
                              </label>
                              <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  value={orderInfo.name}
                                  onChange={handleInputChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm sm:text-base"
                                  required
                              />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700" htmlFor="phone">
                                  Phone Number
                              </label>
                              <input
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  value={orderInfo.phone}
                                  onChange={handleInputChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm sm:text-base"
                                  required
                              />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                                  Email Address
                              </label>
                              <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  value={orderInfo.email}
                                  onChange={handleInputChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm sm:text-base"
                                  required
                              />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700" htmlFor="address">
                                  Delivery Address
                              </label>
                              <textarea
                                  id="address"
                                  name="address"
                                  value={orderInfo.address}
                                  onChange={handleInputChange}
                                  rows="3"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm sm:text-base"
                                  required
                              />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700" htmlFor="quantity">
                                  Quantity
                              </label>
                              <input
                                  type="number"
                                  id="quantity"
                                  name="quantity"
                                  value={orderInfo.quantity}
                                  onChange={handleInputChange}
                                  min="1"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm sm:text-base"
                                  required
                              />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700" htmlFor="color">
                                  Select Color
                              </label>
                              <select
                                  id="color"
                                  name="color"
                                  value={orderInfo.color}
                                  onChange={handleInputChange}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm sm:text-base"
                                  required
                              >
                                  <option value="">Select a color</option>
                                  {colors.map((color) => (
                                      <option key={color.id} value={color.name}>{color.name}</option>
                                  ))}
                              </select>
                          </div>
  
                          {error && (
                              <div className="text-sm text-red-600 bg-red-50 border border-red-600 rounded-md p-2 mb-4">
                                  {error}
                              </div>
                          )}
  
                          <button
                              type="submit"
                              className ="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm sm:text-base"
                              aria-label="Place Order"
                          >
                              {isSubmitting ? (
                                  <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                              ) : (
                                  <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
                              )}
                              Place Order
                          </button>
                      </form>
                  </div>
              </div>
          </main>

          {isModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg p-4">
            <img 
                src={product.image} 
                alt={product.name} 
                className="max-w-full max-h-[90vh] transition-transform duration-300 transform hover:scale-10" 
            />
            <button 
                onClick={toggleModal} 
                className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
            >
                Close
            </button>
        </div>
    </div>
)}
      </div>
  );
};

export default ProductDetail;