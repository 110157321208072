import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const Card = ({ customerId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from('product')
        .select('*');

      if (error) {
        console.error('Error fetching data:', error);
      } else {
        setData(data);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;

  if (!data || data.length === 0) {
    return <div>No products available</div>;
  }

  const lastFourItems = data.slice(-4);

  return (
    <div className="container mx-auto px-4 py-8">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
    {lastFourItems.map((item) => (
      <Link to={`/product/${item.id}`} key={item.id} className="block">
        <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-4 sm:px-6 pb-4 sm:pb-6 pt-40 sm:pt-48 max-w-sm mx-auto mt-8 sm:mt-12">
          <img 
            src={item.image} 
            alt={item.name} 
            className="absolute inset-0 h-full w-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-gray-900/80 to-transparent"></div>
          <h3 className="z-10 mt-2 sm:mt-3 text-xl sm:text-2xl lg:text-3xl font-bold text-white">{item.name}</h3>
          <div className="z-10 gap-y-1 overflow-hidden text-sm sm:text-base leading-6 text-gray-300">Contact for price</div>
        </article>
      </Link>
    ))}
  </div>
</div>
  );
};

export default Card;