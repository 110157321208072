import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const OrderSuccess = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <h1 className="text-2xl font-bold text-gray-900">Order Successful!</h1>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto">
          <div className="text-center">
            <FontAwesomeIcon 
              icon={faCheckCircle} 
              className="text-teal-500 text-6xl mb-6" 
            />
            <h2 className="text-2xl font-semibold mb-4">
              Your order has been successfully placed!
            </h2>
            
            <div className="space-y-4">
              <p className="text-gray-600">
                Thank you for shopping with us. You will receive an email with your order details shortly.
              </p>
              
              <div className="bg-blue-50 p-4 rounded-lg mt-6">
                <div className="flex items-center justify-center mb-2">
                  <FontAwesomeIcon 
                    icon={faPhoneAlt} 
                    className="text-blue-600 mr-2" 
                  />
                  <span className="font-semibold text-blue-800">
                    Price Negotiation
                  </span>
                </div>
                <p className="text-blue-700">
                  Our team will contact you shortly to discuss pricing and finalize your order details.
                </p>
              </div>

              <div className="mt-8">
                <Link 
                  to="/" 
                  className="inline-block bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition duration-300"
                >
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Additional Information */}
        <div className="mt-8 max-w-2xl mx-auto">
          <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
            <h3 className="text-lg font-semibold mb-3">What happens next?</h3>
            <ul className="space-y-3 text-gray-600">
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">1.</span>
                Our team will review your order
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">2.</span>
                We'll call you to discuss pricing and confirm details
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">3.</span>
                Once confirmed, we'll process your order
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">4.</span>
                You'll receive a confirmation email with final details
              </li>
            </ul>
          </div>
        </div>

        {/* Contact Information */}
        <div className="text-center mt-8 text-gray-600">
          <p>If you have any questions, please contact us at:</p>
          <p className="font-semibold"> email | Phone</p>
        </div>
      </main>
    </div>
  );
};

export default OrderSuccess;