import React, { useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import { CloudUploadIcon, XIcon } from '@heroicons/react/outline';
import Slider from 'react-slick';

const ImageUploader = ({ 
    maxFileSize = 5 * 1024 * 1024, // 5MB default
    acceptedFormats = ['image/jpeg', 'image/png', 'image/gif'] 
}) => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);

    const validateFile = (selectedFile) => {
        if (selectedFile.size > maxFileSize) {
            setError(`File is too large. Max size is ${maxFileSize / 1024 / 1024}MB`);
            return false;
        }

        if (!acceptedFormats.includes(selectedFile.type)) {
            setError('Invalid file format');
            return false;
        }

        return true;
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        processFiles(selectedFiles);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.dataTransfer.files);
        processFiles(selectedFiles);
    };

    const processFiles = (selectedFiles) => {
        setError(null);
        const validFiles = selectedFiles.filter(validateFile);
        const filePreviews = validFiles.map(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFiles(prevFiles => [...prevFiles, { file, preview: reader.result }]);
            };
            reader.readAsDataURL(file);
            return { file };
        });
        setFiles(prevFiles => [...prevFiles, ...filePreviews]);
    };

    const handleUpload = async () => {
        if (!files.length) return;
        setUploading(true);
        setError(null);

        try {
            for (const { file } of files) {
                const { data, error } = await supabase.storage
                    .from('Gallery')
                    .upload(`${file.name}`, file);

                if (error) throw error;

                // Optional: Fetch the public URL
                const { data: publicUrl } = supabase
                    .storage
                    .from('Gallery')
                    .getPublicUrl(`${file.name}`);
                
                console.log('Uploaded Image URL:', publicUrl.publicUrl);
            }

            setFiles([]);
            fileInputRef.current.value = '';
        } catch (uploadError) {
            setError(uploadError.message || 'Upload failed');
        } finally {
            setUploading(false);
        }
    };

    const clearFiles = () => {
        setFiles([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white rounded-2xl shadow-2xl">
            <div 
                className={`border-2 border-dashed rounded-xl p-6 text-center 
                    transition-all duration-300 group
                    ${files.length ? 'border-green-500 bg-green-50' : 'border-gray-300 hover:border-blue-500 hover:bg-blue-50'}
                `}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
            >
                <input
                    type="file"
 ref={fileInputRef}
                    className="hidden"
                    accept={acceptedFormats.join(',')}
                    onChange={handleFileChange}
                    multiple
                    disabled={uploading}
                />
                {!files.length && (
                    <div className="flex flex-col items-center space-y-4">
                        <CloudUploadIcon className="h-16 w-16 text-gray-400 group-hover:text-blue-500 transition-colors" />
                        <div>
                            <p className="text-sm text-gray-600">Drag and drop your images here</p>
                            <button
                                type="button"
                                onClick={() => fileInputRef.current.click()}
                                className="mt-2 px-4 py-2 bg-blue-50 text-blue-600 
                                rounded-lg hover:bg-blue-100 transition-colors"
                            >
                                Browse Files
                            </button>
                        </div>
                    </div>
                )}
                {files.length > 0 && (
                    <Slider {...settings}>
                        {files.map(({ file, preview }, index) => (
                            <div key={index} className="relative">
                                <img 
                                    src={preview} 
                                    alt={`File preview ${index + 1}`} 
                                    className="mx-auto max-h-64 rounded-lg shadow-md object-contain"
                                />
                                <div className="absolute top-2 right-2 flex space-x-2">
                                    <button 
                                        onClick={clearFiles}
                                        className="bg-white/75 p-1 rounded-full hover:bg-white"
                                    >
                                        <XIcon className="h-5 w-5 text-gray-700" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
            {error && (
                <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 text-sm">
                    {error}
                </div>
            )}
            {files.length > 0 && (
                <button
                    onClick={handleUpload}
                    disabled={uploading}
                    className={`mt-4 w-full py-3 rounded-lg transition-colors 
                        flex items-center justify-center space-x-2
                        ${uploading ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}
                    `}
                >
                    {uploading ? (
                        <>
                            <span className="animate-spin">◳</span>
                            <span>Uploading...</span>
                        </>
                    ) : (
                        <>
                            <CloudUploadIcon className="h-5 w-5" />
                            <span>Upload Images</span>
                        </>
                    )}
                </button>
            )}
        </div>
    );
};

export default ImageUploader;